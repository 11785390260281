import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const smoothScroll = (e) => {
      e.preventDefault();
      const targetId = e.currentTarget.getAttribute("href");
      const targetElement = document.querySelector(targetId);
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      setIsMenuOpen(false);
    };

    const links = document.querySelectorAll('nav a');
    links.forEach(link => {
      link.addEventListener('click', smoothScroll);
    });

    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      sections.forEach(section => {
        const rect = section.getBoundingClientRect();
        const isVisible = (rect.top <= window.innerHeight * 0.75) && (rect.bottom >= 0);
        if (isVisible) {
          section.classList.add('visible');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => {
      links.forEach(link => {
        link.removeEventListener('click', smoothScroll);
      });
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <header>
        <div className="header-content">
          <h1>عبدالعزيز بن زيد</h1>
          <h2>Abdulaziz bin Zaid</h2>
        </div>
        <button className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          ☰
        </button>
        <nav className={isMenuOpen ? 'open' : ''}>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#early-life">Early Life</a></li>
            <li><a href="#career">Career</a></li>
            <li><a href="#diplomatic-achievements">Diplomacy</a></li>
            <li><a href="#legacy">Legacy</a></li>
          </ul>
        </nav>
      </header>

      <main>
        <section id="home" className="hero visible">
          <div className="hero-content">
            <h2>Welcome to the Life of Abdulaziz bin Zaid</h2>
            <p>Discover the remarkable journey of Abdulaziz bin Zaid, a pivotal figure in Saudi Arabian history whose contributions in diplomacy and administration were instrumental during the reigns of King Abdulaziz and King Saud.</p>
            <h3>Quick Facts:</h3>
            <ul>
              <li><strong>Full Name:</strong> Abdulaziz bin Hamod bin Zaid</li>
              <li><strong>Birthplace:</strong> Hail, Saudi Arabia</li>
              <li><strong>Occupations:</strong> Diplomat, Administrator</li>
              <li><strong>Key Roles:</strong> Member of the Consultative Council, Assistant Qaim Maqam of Jeddah, Inspector of the Northern Borders, Consul in Damascus and Beirut, Ambassador</li>
            </ul>
          </div>
          <div className="hero-image">
            <img src="/images/image2.jpg" alt="Portrait of Abdulaziz bin Zaid" />
          </div>
        </section>

        <section id="early-life">
          <h2>Early Life</h2>
          <div className="content-with-image">
            <div className="text-content">
              <p>Abdulaziz bin Zaid was born into a prestigious family in Hail, known for their influence and contributions to the region's administration. His father, Sheikh Hamoud bin Zaid, served as the Emir of Buraidah during the rule of Prince Mohammed bin Abdullah Al Rashid over Najd. Although there are differing accounts of his birth year, it is commonly believed that Abdulaziz bin Zaid was born in 1315 AH (1897 CE), coinciding with the year Prince Mohammed Al Rashid passed away.</p>
              <p>As a child, Abdulaziz received his early education in Hail's traditional schools known as kuttabs, focusing on the Quran and Islamic teachings. Given Hail's reputation as a center of learning, he also studied under the renowned scholar Sheikh Saleh bin Salim Al-Banyan. His uncle, Sheikh Rashid Al-Nasir Al-Layla, recognized his potential and took him to Istanbul, where Abdulaziz spent eight formative years mastering the Turkish language and furthering his education. This experience abroad significantly broadened his horizons and equipped him with the cultural and linguistic skills essential for his future roles in diplomacy.</p>
              <p>In Istanbul, Abdulaziz's education included a wide range of subjects beyond traditional Islamic teachings. He studied the Turkish language, which became a valuable asset in his diplomatic career. Additionally, he was exposed to the political and social changes occurring in the Ottoman Empire, which influenced his understanding of governance and diplomacy. These early experiences laid a strong foundation for his future roles in Saudi Arabia's administration and diplomacy.</p>
            </div>
            <div className="image-container">
              <img src="/images/image1.jpg" alt="Abdulaziz bin Zaid with another official" />
            </div>
          </div>
        </section>

        <section id="career">
          <h2>Career Milestones</h2>
          <h3>Transition and Early Responsibilities</h3>
          <p>Following the integration of Hail into King Abdulaziz's kingdom, Abdulaziz bin Zaid quickly became a trusted figure due to his negotiation skills and administrative expertise. One of his first significant assignments was to resolve tribal disputes with the Emirate of Transjordan. His successful mediation underscored his adeptness in diplomacy and established his reputation as a skilled negotiator.</p>
          <p>Abdulaziz's early responsibilities also included managing internal conflicts and ensuring the smooth integration of newly acquired territories into the kingdom. His ability to navigate complex tribal dynamics and build alliances with local leaders was instrumental in maintaining stability and unity within the kingdom. These efforts earned him the trust and respect of King Abdulaziz, who recognized his exceptional diplomatic skills and competence in administration.</p>
          
          <h3>Member of the Consultative Council and Assistant Qaim Maqam of Jeddah</h3>
          <p>Abdulaziz's competence led to his appointment as a member of the Consultative Council, where he advised King Abdulaziz on various state matters. His role expanded further when he became the assistant to the Qaim Maqam of Jeddah, tasked with managing the daily administrative functions of this crucial port city. This role was essential in maintaining peace and order in one of the kingdom's most vital hubs.</p>
          <p>As a member of the Consultative Council, Abdulaziz contributed to shaping national policies and governance structures. He played a key role in discussions and decision-making processes that influenced the kingdom's development. His insights and recommendations were highly valued, and he became a trusted advisor to the king. In his capacity as the assistant to the Qaim Maqam of Jeddah, Abdulaziz oversaw the city's administration, including maintaining law and order, managing trade and commerce, and ensuring the well-being of its residents. His efficient management and ability to address the needs of the diverse population in Jeddah were pivotal in strengthening the city's role as a major economic and cultural center.</p>
          
          <h3>Inspector of the Northern Borders</h3>
          <p>In another key role, Abdulaziz bin Zaid served as the Inspector of the Northern Borders, where he managed complex geopolitical relationships with neighboring tribes to ensure regional stability. His office in Al-Qurayyat became a hub of diplomatic activity, earning him the moniker "Diplomat of the Desert" for his sharp intellect and wise diplomacy. Abdulaziz demonstrated a deep understanding of tribal dynamics and leveraged his insights to maintain peace across the vast and often contentious borderlands.</p>
          <p>As the Inspector of the Northern Borders, Abdulaziz was responsible for maintaining security and stability in a region characterized by complex tribal relationships and frequent disputes. He worked closely with tribal leaders to mediate conflicts and build alliances that ensured peaceful coexistence. His deep knowledge of tribal customs and traditions, as well as his ability to communicate effectively with tribal leaders, were instrumental in his success. Abdulaziz's diplomatic efforts in the northern borders were essential for the kingdom's security and played a crucial role in establishing Saudi Arabia's influence in the region.</p>
        </section>

        <section id="diplomatic-achievements">
          <h2>Diplomatic Achievements</h2>
          <div className="content-with-image reverse">
            <div className="text-content">
              <h3>Consul in Damascus and Beirut</h3>
              <p>In 1357 AH (1938 CE), Abdulaziz bin Zaid was appointed as the Saudi Consul in Damascus. His prior familiarity with the city from his earlier studies proved invaluable in his diplomatic duties. Abdulaziz's tenure as consul—and later as the first Saudi ambassador to Syria and Lebanon—was marked by significant challenges, including managing complex regional dynamics and fortifying Saudi sovereignty and influence in these countries. His 22-year tenure in the Levant cemented his status as a cornerstone of Saudi diplomacy, where his efforts were pivotal in strengthening bilateral relations.</p>
              <p>As consul in Damascus, Abdulaziz played a key role in representing Saudi interests and fostering diplomatic relations with Syria. He worked tirelessly to promote mutual understanding and cooperation between the two countries. His ability to navigate the complex political landscape and build strong relationships with Syrian officials was instrumental in advancing Saudi Arabia's diplomatic objectives. Abdulaziz's contributions extended beyond official diplomatic duties. He actively engaged with the local community, participating in cultural events and promoting Saudi culture and values. His presence in Damascus and later in Beirut contributed to a deeper understanding and appreciation of Saudi Arabia in the Levant region.</p>
              
              <h3>Conference Representation and Boundary Negotiations</h3>
              <p>Abdulaziz played a crucial role in numerous boundary negotiations and regional conferences, representing Saudi interests in discussions with Syria, Jordan, and Iraq. His participation in the Tadmur conference and other similar events highlighted his ability to address tribal conflicts and other diplomatic issues effectively. These conferences were essential for paving the way for lasting peace and stability in the region.</p>
              <p>Throughout his diplomatic career, Abdulaziz was involved in various regional conferences and negotiations that addressed critical issues of boundary disputes, tribal conflicts, and regional cooperation. His deep knowledge of the region's history, culture, and politics, combined with his exceptional negotiation skills, made him the ideal representative for Saudi Arabia in these forums. Abdulaziz's efforts in boundary negotiations were particularly significant, as they helped to establish clear and mutually agreed-upon borders, reducing the potential for conflicts and promoting stability. His ability to find common ground and build consensus among diverse stakeholders was a testament to his diplomatic acumen and dedication to regional peace.</p>
            </div>
            <div className="image-container">
              <img src="/images/image3.jpg" alt="Abdulaziz bin Zaid with a Saudi leader" />
            </div>
          </div>
        </section>

        <section id="legacy">
          <h2>Legacy</h2>
          <p>Abdulaziz bin Zaid's legacy is marked by his unwavering loyalty, strategic diplomatic foresight, and substantial contributions to the administrative and diplomatic framework of early Saudi Arabia. His career spanned critical formative years for the kingdom, during which he played a vital role in stabilizing and developing its governance structures and external relations. His efforts were widely acknowledged and appreciated by his contemporaries, with newspapers of the time praising his dedication, moral integrity, and commitment to Arab and Islamic values. Abdulaziz was regarded as a symbol of integrity and devotion, embodying the spirit of public service integral to the progress of Saudi society.</p>
          <p>Abdulaziz's legacy extends beyond his official roles and accomplishments. He was a pioneer in establishing a professional diplomatic corps in Saudi Arabia, setting high standards for ethical conduct and effective diplomacy. His influence can be seen in the development of Saudi Arabia's foreign policy and its engagement with the international community. His contributions to the kingdom's administrative framework provided a foundation for modern governance practices, ensuring transparency, accountability, and efficient administration. Abdulaziz's life and work continue to inspire future generations of diplomats, administrators, and leaders, who look to his example of dedication, integrity, and visionary leadership.</p>
          
          <h3>Final Years and Passing</h3>
          <p>Abdulaziz bin Zaid continued his service until his death during King Saud's reign in 1379 AH (1959 CE). His passing marked the end of an era, but his contributions left an enduring legacy that continues to be remembered and respected. King Saud, deeply saddened by his death, sent a heartfelt telegram to Abdulaziz's family, expressing his condolences and acknowledging Abdulaziz's invaluable service and loyalty. Abdulaziz's life's journey is a testament to the impact of dedicated public service and visionary leadership, characteristics that continue to inspire future generations.</p>
          <p>In his final years, Abdulaziz remained actively involved in diplomatic and administrative affairs, continuing to contribute his expertise and wisdom to the kingdom. His dedication to public service never waned, and he remained committed to advancing Saudi Arabia's interests and promoting regional stability. Abdulaziz's passing was mourned by many who recognized his significant contributions to the nation and the region. His funeral was attended by dignitaries, diplomats, and community leaders, reflecting the widespread respect and admiration he earned throughout his life. The tributes and condolences that poured in following his death highlighted his profound impact on Saudi society and his enduring legacy as a distinguished statesman and leader.</p>
        </section>
      </main>

      <footer>
        <p>&copy; 2023 Abdulaziz bin Zaid Life History. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;